<template>
  <div>
    <a-modal
      v-model="isShow"
      width="300px"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="绑定邮箱"
      @ok="handleSubmit"
    >
      <a-form
        class="iotplt-compact-form"
        :form="form"
        @submit="handleSubmit"
      >
        <a-form-item>
          <a-input
            v-decorator="['email', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入联系邮箱' },
                { pattern: /^[a-z0-9._%-]+@([a-z0-9-]+\.)+[a-z]{2,4}$/, message: '邮箱格式不正确' },
              ]
            }]"
            placeholder="请输入邮箱地址"
          />
        </a-form-item>

        <a-form-item>
          <a-row :gutter="8">
            <a-col :span="13">
              <a-input
                placeholder="请输入验证码"
                v-decorator="['email_captcha', {
                  normalize: this.$lodash.trim,
                  rules: [{ required: true, message: '请输入验证码' }]
                }]"
              />
            </a-col>
            <a-col :span="11">
              <a-button
                type="primary"
                :block="true"
                class="iotplt-register-form-captcha-button"
                @click="getEmailCaptcha"
                :disabled="disabled"
              >
                {{ btnTitle }}
              </a-button>
            </a-col>
          </a-row>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { updateUserSelfEmail } from '@/api/user'
import { sendEmailCaptchaBeforBind } from '@/api/captcha'
export default {
  name: 'UpdateUserSelfEmail',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'edit_full_name' }),
      submitting: false,
      disabled: false,
      btnTitle: '获取验证码',
      businessLogic: 'editEmail'
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    getEmailCaptcha(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields(['email'], (err, values) => {
        if (!err) {
          this.submitting = true
          sendEmailCaptchaBeforBind(values).then((res) => {
            if (res.code === 0) {
              this.validateBtn()
            }
            this.submitting = false
          })
        }
      })
    },

    validateBtn() {
      // 倒计时
      let time = 60
      const timer = setInterval(() => {
        if (time === 0) {
          clearInterval(timer)
          this.disabled = false
          this.btnTitle = '获取验证码'
        } else {
          this.btnTitle = time + '秒后重试'
          this.disabled = true
          time -= 1
        }
      }, 1000)
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          updateUserSelfEmail(this.id, values).then((res) => {
            if (res.code === 0) {
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
